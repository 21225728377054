import React from 'react'

import { Link, Paragraph, UnauthorizedPage } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { Code } from '../../components/Code'
import { LinkMock } from '../../components/LinkMock'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="UnauthorizedPage"
    components={[{ component: UnauthorizedPage }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Paragraph>
        <Code>UnauthorizedPage</Code>-komponenttia käytetään sivupohjissa{' '}
        <Code>Columns</Code>-komponentin tilalla.
      </Paragraph>
      <Playground>
        <UnauthorizedPage
          heading="Lorem ispum dolor sit amet"
          content={
            <>
              <Paragraph align="center">
                Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
                sapien.
              </Paragraph>
              <Paragraph
                align="center"
                noMargin
              >
                Sed arcu lectus auctor vitae, consectetuer et{' '}
                <Link as={LinkMock}>venenatis eget velit</Link>.
              </Paragraph>
            </>
          }
        />
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Huolehdi, että sivun teksti on kuvaava ja se sisältää linkin, jonka
        avulla käyttäjä pystyy palaamaan esimerkiksi etusivulle.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
